// export const apiHostName = 'http://192.168.0.102:5001';
// export const apiHostName = `${window.location.protocol}//${window.location.hostname}:5001`;
// export const apiHostName = 'https://localhost:44347';
export const apiHostName = 'http://autorobodnz-001-site3.etempurl.com';


export const host = 'http://localhost:3008'
export const authority = "http://api.mansurtraders.com";


