import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import userManager, { loadUserFromStorage } from './views/Auth/UserService';
import store from './store';
import { Provider } from 'react-redux';
import AuthProvider from './views/Auth/utils/authProvider';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const SigninOidc = React.lazy(() => import('./views/Auth/signin-oidc'));
const SignoutOidc = React.lazy(() => import('./views/Auth/signout-oidc'));

const App = () => {
  useEffect(() => {
    loadUserFromStorage(store);
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider userManager={userManager} store={store}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route exact path="/signin-oidc" name="Signin OIDC" render={props => <SigninOidc {...props} />} />
                <Route exact path="/signout-oidc" name="Signout OIDC" render={props => <SignoutOidc {...props} />} />
                <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
              </Switch>
            </React.Suspense>
          </Router>
        </I18nextProvider>
      </AuthProvider>
      <NotificationContainer />
    </Provider>
  );
};

export default App;