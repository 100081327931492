import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations.json';

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    lng: savedLanguage, // use saved language or default to English
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React already safes from xss
    }
  });

export default i18n;
